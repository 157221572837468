<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search />
    </div>
    <div class="block-detial-box">
      <el-card class="box-card">
        <div slot="header">
          <div class="card-header">
            {{ $t("交易") }}
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("交易哈希") }}:</div>
          <div class="value">{{ transactionInfo.hash }}</div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("狀態") }}:</div>
          <div class="value">
            {{ transactionInfo.status ? $t("成功") : $t("失敗") }}
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("區塊") }}:</div>
          <div class="value">{{ transactionInfo.blockNumber }}</div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("時間戳") }}:</div>
          <div class="value">
            {{
              dateFormat(
                "yyyy-MM-dd hh:mm:ss",
                new Date(transactionInfo.timestamp * 1000 || 0)
              )
            }}
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("發送方") }}:</div>
          <div class="value">{{ transactionInfo.from }}</div>
        </div>
        <el-divider></el-divider>
        <div class="item flex subset-wrap">
          <div class="title">{{ $t("接收方") }}:</div>
          <div class="right">
            <div class="value" v-if="transactionInfo.to">
              {{ transactionInfo.to }}
            </div>
            <div v-else class="value">
              {{ $t("建立合約") }}
              {{ transactionInfo.contractAddress }}
            </div>
            <div class="subset">
              <div
                class="value"
                v-for="(internal, index) in transactionInfo.internals"
                :key="index"
              >
                {{ $t("發送方") }}:
                <router-link
                  :to="{
                    name: 'AddressDetail',
                    params: { address: internal.from }
                  }"
                  >{{ internal.from }}</router-link
                >
                &nbsp;&nbsp;{{ $t("接收方") }}:

                <router-link
                  :to="{
                    name: 'AddressDetail',
                    params: { address: internal.to }
                  }"
                  >{{ internal.to }}</router-link
                >
                &nbsp;&nbsp;
                <router-link
                  :to="{
                    name: 'AddressDetail',
                    params: { address: internal.contract }
                  }"
                  >{{ internal.value }}{{ internal.token.symbol }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("價值") }}:</div>
          <div class="value">
            {{ web3.utils.fromWei(transactionInfo.ethValue || "0", "ether") }}
            back
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("交易費用") }}:</div>
          <div class="value">
            {{
              web3.utils.fromWei(
                `${transactionInfo.gasPrice * transactionInfo.gasUsed || 0}`,
                "ether"
              )
            }}
            back
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("燃料價格") }}:</div>
          <div class="value">
            {{ web3.utils.fromWei(transactionInfo.gasPrice || "0", "Gwei") }}
            Gwei
          </div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("燃料限制") }}:</div>
          <div class="value">{{ transactionInfo.gas }}</div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">nonce:</div>
          <div class="value">{{ transactionInfo.nonce }}</div>
        </div>
        <el-divider></el-divider>
        <div class="item flex-middle">
          <div class="title">{{ $t("數據輸入") }}:</div>
          <div class="value">{{ transactionInfo.input }}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTransactionInfo } from "@/api";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import HeaderSearch from "@/components/HeaderSearch";
import Web3 from "web3";
export default {
  components: {
    HeaderSearch
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const store = useStore();
    const data = reactive({
      $t: t,
      web3: Web3,
      hash: route.params.hash,
      transactionInfo: {}
    });
    onMounted(() => {
      getTransactionInfo({ hash: data.hash }).then(res => {
        data.transactionInfo = res.data;
      });
    });
    onBeforeRouteUpdate((to, from) => {});
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.block-detial-box {
  max-width: 1400px;
  margin: -100px auto 80px;
  box-shadow: 0px 4px 12px 0px rgba(10, 15, 23, 0.02);
  border-radius: 20px;
  .el-card__body {
    border-radius: 20px;
  }
}
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item,
.subset-wrap {
  font-size: 16px;
  margin-top: 20px;
  color: #0a0f17;
  text-align: left;
  .title {
    width: 20%;
    color: #666;
  }

  .value {
    flex: 1;
    word-break: break-all;
  }
}
@media screen and (max-width: 480px) {
  .block-detial-box {
    margin: -20px 15px 20px;
    border-radius: 10px;
    .el-card__body {
      border-radius: 10px;
    }
  }
  .card-header {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .item,
  .subset-wrap {
    font-size: 16px;
    margin-top: 20px;
    color: #0a0f17;
    text-align: left;
    .title {
      min-width: 100px;
      color: #666;
    }
    .value {
      flex: 1;
      word-break: break-all;
    }
  }
}
</style>
